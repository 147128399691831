/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';

// componentes 
import { BreadcrumbComponent } from "../shared/components/breadcrumb/breadcrumb.component";

// módulos  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

// interfaces
import { IApiDataStock } from './iDataStock';
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IAlmacen } from 'app/interfaces/almacenes';

// servicios
import { StockService } from 'app/services/datos/stock.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { TransaccionesService } from 'app/services/datos/transacciones.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerías
import { TableElement, TableExportUtil } from '@shared';
import { ITransaccion } from 'app/interfaces/transacciones';
import FuncLib from 'app/providers/funcionesLib';



@Component({
  selector: 'app-stock',
  standalone: true,
  templateUrl: './stock.component.html',
  styleUrl: './stock.component.scss',
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, MatSortModule, MatPaginatorModule,
    MatFormFieldModule, MatInputModule]
})


export class StockComponent implements OnInit {

  almacenesList: IAlmacen[] = [];
  almSeleccionado: string = this.usuarioSrv.userdata.alm_por_defecto || '0';    // Almacén por defecto


  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  breadscrums = [{ title: 'Listado de Stock', items: ['Inicio'], active: 'Stock', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}

  public funcLib: FuncLib = new FuncLib();

  displayedColumns: string[] = ['id_articulo', 'articuloSku', 'articuloEan', 'descripcion', 'lote', 'fechaCaducidad', 'fechaEntrada', 'cantidad', 'cantidadReservada', 'cantidadDisponible'];
  dataSource = new MatTableDataSource<IApiDataStock>([]);

  constructor(
    private stockSrv: StockService,
    public almacenesSrv: AlmacenesService,
    private usuarioSrv: UsuarioService,
    private transSrv: TransaccionesService,
    private notificSrv: NotificacionesService
  ) { }

  ngOnInit(): void {
    this.getListaStock();
    this.getListaAlmacenes();
    if (this.usuarioSrv.userdata.cliente?.agrupa_ubicaciones != '1') {
      //mostrar ubiCodigo en la 5ª columna
      this.displayedColumns.splice(4, 0, 'ubiCodigo');
    }
  }


  getListaStock() {
    this.opcionesList.id_almacen = parseInt(this.almSeleccionado);
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id;
    this.opcionesList.agrupado = this.usuarioSrv.userdata.cliente!.agrupa_ubicaciones;

    this.loading = true;
    this.stockSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        this.dataSource.data = resp.data;
        console.log('__getListaStock() ', resp)
      })
  }

  // Almacenes
  getListaAlmacenes() {
    this.loading = true;
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }

  // Cambia almacén
  onAlmacenSelect(id: string) {
    this.almSeleccionado = id;
    this.getListaStock();
  }





  // Buscar
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }





  // Totales
  getTotalCantidad(): number {
    return this.dataSource.filteredData.reduce((total, rec: IApiDataStock) => {
      const cantidadNum = parseInt(rec.cantidad!) || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }
  getTotalDisponible(): number {
    return this.dataSource.filteredData.reduce((total, rec: IApiDataStock) => {
      const cantidadNum = parseInt(rec.cantidadDisponible!) || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }

  getTotalResvada(): number {
    return this.dataSource.filteredData.reduce((total, rec: IApiDataStock) => {
      const cantidadNum = parseInt(rec.cantidadReservada!) || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }


  /// AUX ///



  // Método para determinar si la fecha es anterior a hoy
  isExpired(fecha: string): boolean {
    if (!fecha) return false

    const hoy = new Date();

    console.log('__isExpired()', fecha)

    return new Date(fecha) < hoy;
  }



  // export table data in excel file
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      this.dataSource.filteredData.map((x) => ({
        'Id de artículo': x.id_articulo,
        'Sku': x.articuloSku,
        'Ean': x.articuloEan,
        'Descripción': x.descripcion,
        'Lote': x.lote,
        'Fecha de caducidad': x.fechaCaducidad ? formatDate(new Date(x.fechaCaducidad), 'dd/MM/yyyy', 'en') : '',
        'Cantidad': x.cantidad,
        'Cantidad Reservada': x.cantidadReservada,
        'fecha Entrada': x.fechaEntrada ? formatDate(new Date(x.fechaEntrada), 'dd/MM/yyyy', 'en') : '',
      }));

    console.log('exportData', exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'excel');

  }


  // export table transacciones in excel file
  downloadTranacciones() {
    this.notificSrv.aviso('info', `Generando xlsx de Transacciones...`);

    const data = {
      id_almacen: null,
      id_cliente: this.usuarioSrv.userdata.cliente!.id || 99999999,
      id_articulo: null,
      id_ubicacion: null
    };
    this.loading = true;
    this.transSrv.getListaFiltrada(data)

      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        const transaccionesList = resp.data;
        if (!transaccionesList) {
          this.notificSrv.aviso('warning', `No se han encontrado Transacciones`);
          return
        }

        const exportData: Partial<TableElement>[] =
          transaccionesList.map((x: ITransaccion) => ({
            'id_articulo': x.id_articulo,
            'EAN': x.articulo?.ean,
            'Sku': x.articulo?.sku,
            'Artículo': x.articulo?.descripcion,
            'Ubicación': x.ubicacion,
            'Cantidad': x.cantidad,
            'Descripcion': x.descripcion,
            'Motivo': x.motivo,
            'Fecha': formatDate(new Date(x.fecha!), 'dd/MM/yyyy HH:mm', 'en') || ''
          }));

        console.log('exportData', exportData)
        if (!exportData.length) {
          this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
          return
        }
        TableExportUtil.exportToExcel(exportData, 'excel');
      })
  }

}
