import { HttpInterceptorFn } from '@angular/common/http';

export const valTokenInterceptor: HttpInterceptorFn = (req, next) => { 
  
  // Verificar si es una solicitud de inicio de sesión o un refreshToken
  if (req.url.includes('/login')) {
    return next(req);
  }
  if (req.url.includes('/refreshToken')) {
    return next(req);
  }
  


  // Validar si hay avios nuevos
  


  // Agregar cabecera de autenticación para todas las demás solicitudes

  console.log("Add cabecera desde valTokenInterceptor para:", req.urlWithParams);

  const token = localStorage.getItem('token') || '';
  const headers = req.headers.append('Authorization', 'Bearer ' + token);
  const authRequest = req.clone({ headers });

  return next(authRequest);
};
