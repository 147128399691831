/* eslint-disable @typescript-eslint/no-explicit-any */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MaterialModule } from 'app/auth/material/material.module';

// Servicios
import { UsuarioService } from 'app/services/datos/usuario.service';
import { ModalImagenService } from 'app/services/modal-imagen.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { UploadFileService } from 'app/services/upload-file.service';


@Component({
  selector: 'app-modal-imagen',
  templateUrl: './modal-imagen.component.html',
  styleUrls: ['./modal-imagen.component.css'],
  standalone: true,
  imports: [CommonModule, MaterialModule],

})
export class ModalImagenComponent {

  loading: boolean = false;

  public imagenSubir: File | undefined;
  public imgTemp: any = null;

  constructor(
    public modalImagenSrv: ModalImagenService,
    private uploadFileSrv: UploadFileService,
    private notificacionesService: NotificacionesService,
    private usuarioSrv: UsuarioService
  ) { }

  cerrarModal() {
    this.imgTemp = null;
    this.modalImagenSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    if (this.loading) return;
    // Cerrar modal al hacer click fuera del modal si no está subiendo un archivo
    if ((event.target as HTMLElement).classList.contains('modal')) {
      this.cerrarModal();
    }
  }


  subirFoto(event: Event) {

    const inputElement = event.target as HTMLInputElement;
    const file: File | undefined = inputElement.files?.[0] || undefined;

    this.loading = true;
    if (file) this.uploadFileSrv.uploadFoto(this.modalImagenSrv.id, file, this.modalImagenSrv.tipo)
      .then(resp => {
        this.loading = false;
        if (!resp.error) {

          // emitir la imagen a los subscriptores
          this.modalImagenSrv.nuevaImagen.emit(resp.data);

          // si es del usuario logueado, actualizo su foto
          if (this.modalImagenSrv.id === this.usuarioSrv.userdata.id?.toString()) this.usuarioSrv.userdata.foto = resp.data.guardado_como;

          this.notificacionesService.aviso('success', 'Foto cambiada');
          this.cerrarModal();
        } else {
          console.log('Error en la subida de imagen', resp)
          this.notificacionesService.aviso('error', resp.mensaje);
        }
      });
  }


  borrarFoto() {
    this.uploadFileSrv.imgDelete(this.modalImagenSrv.tipo, this.modalImagenSrv.id)
      .subscribe(resp => {
        this.loading = false;
        if (!resp.error) {
          this.notificacionesService.aviso('info', 'Foto eliminada');
        } else {
          this.notificacionesService.aviso('error', resp.mensaje);
        }

        this.cerrarModal();

        // emitir la imagen a los subscriptores
        this.modalImagenSrv.nuevaImagen.emit(resp.data);

        // si es del usuario logueado, actualizo su foto
        if (this.modalImagenSrv.id === this.usuarioSrv.userdata.id?.toString()) this.usuarioSrv.userdata.foto = resp.data.guardado_como;

      })
  }

}
