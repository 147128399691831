// Servicio para mostrar Toastr

import { Injectable } from '@angular/core';

// Servicios
import { ToastrService } from 'ngx-toastr';
import { HablaService } from './habla.service';

type TiposDeMensaje = 'error' | 'success' | 'info' | 'warning';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  vozError: boolean = (localStorage.getItem('vozError') === '1') || false;
  vozSuccess: boolean = (localStorage.getItem('vozSuccess') === '1') || false;
  vozInfo: boolean = (localStorage.getItem('vozInfo') === '1') || false;
  vozWarning: boolean = (localStorage.getItem('vozWarning') === '1') || false;


  constructor(
    public toastr: ToastrService,
    public hablaSrv: HablaService,
  ) { }

  aviso(tipo: TiposDeMensaje, payload: string | object) {
    if (typeof payload === 'object') {
      // más de 1 mensaje
      const claves: string[] = Object.keys(payload);
      const valores: string[] = Object.values(payload);
      for (let i = 0; i < claves.length; i++) {
        this.tost(tipo, valores[i], `${claves[i]}`)
      }
    } else {
      // Solo 1 mensaje
      this.tost(tipo, payload, ``);
    }
  }


  tost(tipo: TiposDeMensaje, msg: string, titulo: string) {
    switch (tipo) {
      case 'error':
        this.toastr.error(msg, `ERROR ${titulo}`);
        if (this.vozError) this.hablaSrv.habla(msg)
        break;
      case 'success':
        this.toastr.success(msg, `${titulo}`);
        if (this.vozSuccess) this.hablaSrv.habla(msg)
        break;
      case 'info':
        this.toastr.info(msg, `${titulo}`);
        if (this.vozInfo) this.hablaSrv.habla(msg)
        break;
      case 'warning':
        this.toastr.warning(msg, `${titulo}`);
        if (this.vozWarning) this.hablaSrv.habla(msg)
        break;
      default:
        throw new Error(`Tipo de mensaje no válido: ${tipo}`);
    }
  }



} 
