/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accionCliente',
  standalone: true,
})

export class AccionClientePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // Acción solicitada por el cliente (1= cancelar pedido, 2= Servir con faltas)
    switch (value) {
      case '1':
        return '<span class="badge badge-solid-red">Cancelar</span>';
      case '2':
        return '<span class="badge badge-solid-orange">Servir con faltas</span>';
    }
    return value;

  }

}
