<app-header></app-header>
<app-sidebar></app-sidebar>
<div [dir]="direction">
  <router-outlet></router-outlet>

  <!-- modal para imágenes -->
  <app-modal-imagen></app-modal-imagen>

  <!-- modal para pdfs -->
  <app-modal-pdf></app-modal-pdf>

</div>