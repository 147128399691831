/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient,  } from '@angular/common/http';
import { Injectable } from '@angular/core';

// servicios
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';

// interfaces
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IApiResp } from "app/interfaces/api-resp";
import { IArticulo } from 'app/interfaces/articulo';

// environment y librerías
import { environment } from 'environments/environment';
import { catchError, throwError } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class StockService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/stock/listFiltro`; 
    return this.http.post<IApiResp>(path, _opciones)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  getRec(id: number) {
    const path = `${this.apiURL}/stock/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  newRec(formData: any) {
    const path = `${this.apiURL}/stock/new`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      );
  }

  update(articulo: IArticulo) {
    const path = `${this.apiURL}/stock/update/${articulo.id}`;
    return this.http.post<IApiResp>(path, articulo)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  delete(id: number) {
    const path = `${this.apiURL}/stock/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  stockMueve(datos: any) {
    const path = `${this.apiURL}/stock/stockMueve`;
    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', 'No estás autorizado a hacer esta operación');
  }


}
