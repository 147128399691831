<div class="container-fluid bg_gris">
  <div class="centered-card">

    <mat-card class="custom-card">

      <mat-card-header class="bg-light">
        <div mat-card-avatar
             class="login-header-image"></div>
        <mat-card-title><b>Innova</b>Cen</mat-card-title>
        <mat-card-subtitle>Intranet del cliente</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <span class="error-header ">
          404
        </span>

        <div class="h4 mb-2">
          La página que buscas no existe
        </div>
      </mat-card-content>

      <mat-card-footer class="p-4 bg-light">
        <!-- volver al inicio -->
        <button (click)="goHome()"
                mat-fab
                extended
                class="w-100"
                color="primary"
                type="submit">
          <i class="material-icons">home</i>
          Volver al inicio
        </button>
      </mat-card-footer>

    </mat-card>


  </div>
</div>