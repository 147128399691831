/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usuarioRol',
  standalone: true,
})

export class usuarioRolPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    //  consultas, usuario, admin
    switch (value) {
      case 'consultas':
        return '<span class="badge badge-solid-green">Consultas</span>'
        break;
      case 'usuario':
        return '<span class="badge badge-solid-green">Usuario</span>';
        break;
      case 'admin':
        return '<span class="badge badge-solid-orange">Administrador</span>';
        break;
    }
    return value;

  }

}
