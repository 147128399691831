<div class="addContainer">

  <div class="modalHeader bg-Light modal-box">
    <div class="editRowModal">
      <div class="modalHeader clearfix ">
        <div class="me-2">
          <img src='../../../../assets/images/clipboard.svg'
               alt="Pedido InnovaCen">
        </div>

        <!-- Cabecera -->

        <div class="cabeceraDiv border rounded p-1 ">
          <span class="modal-about">{{ dialogTitle }}</span>
          <div class="ms-1">
            {{ pedido.fecha_pedidoExterno | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="cabeceraDiv border rounded p-1 ms-3">
          <div class="ms-1 cliente">
            {{ pedido.nombre }}<br>
            {{ pedido.cp }} - {{ pedido.poblacion }} {{ pedido.pais_iso }}
          </div>
        </div>


        <div class="cabeceraDiv border rounded p-1 ms-3">
          <div class=" ">
            Campaña {{ pedido.camp_cod }}<br>
            {{ pedido.created_at | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="cabeceraDiv border rounded p-1 ms-3">
          <div class="mb-1"
               [innerHTML]="pedido.estado | pedidoEstado">
          </div>
          <div class=" ms-1">
            {{ pedido.estado_at | date: 'dd/MM/yyyy HH:mm' }}
          </div>
        </div>

        <div class="cabeceraDiv border rounded p-1 ms-3">
          Servicio: {{ pedido.cod_servicio }}<br>
          <span *ngIf=" pedido.contrarrembolso ">
            Reembolso: {{ pedido.contrarrembolso | number: '0.2-2':'es' }} €<br>
          </span>
        </div>


        <!-- el cliente puede solicitar una acción especial -->

        <div *ngIf="pedido.estado === '1' && pedido.aviso_cliente === '1'"
             class="mt-2 ms-5 modal-select">
          <select [(ngModel)]="selectedAccionEspecial"
                  (change)="accionEspecial(pedido)"
                  [disabled]="loading"
                  class="form-select form-select-sm"
                  aria-label=".form-select-sm example">
            <option selected
                    value="0">Acción especial</option>
            <option value="1">No va a salir</option>
            <option value="2">Va a salir como está</option>
          </select>
        </div>


        <!-- el cliente ya solicitó una acción especial -->

        <div *ngIf="pedido.estado === '1' && pedido.accion_cliente"
             class="ms-5 modal-select">
          <div class="cabeceraDiv border rounded p-1 ms-3 d-flex align-items-center flex-wrap-nowrap">
            <span class="me-2">Solicitado:</span>
            <div class="mb-1"
                 [innerHTML]="pedido.accion_cliente | accionCliente"></div>
            <div class="ms-1">{{ pedido.accion_cliente_fecha | date: 'dd/MM/yyyy HH:mm' }}</div>
          </div>
        </div>


      </div>
    </div>
    <button mat-icon-button
            (click)="dialogRef.close()"
            class="modal-close-button"
            aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>

    <!-- Líneas de pedido -->
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">EAN</th>
          <th scope="col">Referencia</th>
          <th scope="col">Descripción</th>
          <th scope="col"
              class="text-end">Cant.</th>
          <th scope="col"
              class="text-end">
            <span *ngIf=" (pedido.estado === '3' || pedido.estado === '5')">Servido</span>
            <span *ngIf=" (pedido.estado === '1' && pedido.aviso_cliente === '1')">Preparado</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let lin of pedido.detall">
          <td> <img [src]="fotoDir +'/articulos' + imgResized + lin.artFoto"
                 loading="lazy"
                 alt="Foto"
                 class="rounded pointer border"
                 height="25px"></td>
          <td>{{ lin.artEan }}</td>
          <td>{{ lin.artSku }}</td>
          <td>{{ lin.artDescripcion }}</td>
          <td class="text-end">{{ lin.cantidad | number: '0.0-0':'es' }}</td>

          <!-- Mostrar la columna cant_repartida SOLO i se ha servido ó está pendiente y tiene aviso -->
          <td *ngIf=" (pedido.estado === '1' && pedido.aviso_cliente === '1') || (pedido.estado === '3' || pedido.estado === '5')"
              class="text-end"
              [ngClass]="{'text-danger': lin.cant_repartida < lin.cantidad}">
            {{ lin.cant_repartida | number: '0.0-0':'es' }}</td>
        </tr>

      </tbody>
    </table>

  </div>
</div>