/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pedidoEstado',
  standalone: true,
})

export class PedidoEstadoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // 1 Pendiente, 3 Servido, 4 Cancelado, 5 Servido parcialmente, 8 Incidencia
    switch (value) {
      case '1':
        return '<span class="badge badge-solid-blue">Pendiente</span>'
        break;
      case '3':
        return '<span class="badge badge-solid-green">Completado</span>';
        break;
      case '4':
        return '<span class="badge badge-solid-red">Cancelado</span>';
        break;
      case '5':
        return '<span class="badge badge-solid-orange">Servido con faltas</span>';
        break;
      case '8':
        return '<span class="badge badge-solid-red">Incidencia</span>';
        break;
    }
    return value;

  }

}
