<nav #navbar
     class="navbar"
     [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#"
         onClick="return false;"
         class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed"
         aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#"
         onClick="return false;"
         class="bars"
         (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand"
         routerLink="dashboard/panel">
        <img src="assets/images/logo.png"
             alt="" />
        <span class="logo-name">Innova<b>Cen</b></span>
      </a>
    </div>

    <div class="collapse navbar-collapse"
         [ngClass]="isNavbarCollapsed ? '' : 'show'">

      <ul class="pull-left collapse-menu-icon">
        <li class="menuBtn">
          <button mat-button
                  (click)="callSidemenuCollapse()"
                  class="sidemenu-collapse nav-notification-icons">
            <app-feather-icons [icon]="'menu'"
                               [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>






      <ul class="nav navbar-nav navbar-right">


        <!-- Entorno -->
        <li class="nav-item ">
          <span>{{entorno}}</span>
        </li>
        <!-- #END# Entorno -->



        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-button
                  class="nav-notification-icons"
                  (click)="callFullscreen()">
            <app-feather-icons [icon]="'maximize'"
                               [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
        <!-- #END# Full Screen Button -->





        <!-- #START# Notifications-->
        <li class="nav-item"
            ngbDropdown>
          <button mat-button
                  matTooltip="{{avisosTitulo}} "
                  [matMenuTriggerFor]="notificationMenu"
                  class="nav-notification-icons">

            <!-- Mostrar el punto rojo si hay avisos in leer -->
            <app-feather-icons [icon]="'bell'"
                               [class.alarm-icon]="avisosNoLeidos"
                               [class]="'header-icon'"></app-feather-icons>

          </button>
          <mat-menu #notificationMenu="matMenu"
                    class="nfc-menu">
            <div class="nfc-header">
              <h5 class="mb-0">Avisos</h5>

              <a class="nfc-mark-as-read"> {{avisosTitulo}}</a>

            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 350px"
                            visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div>
                      @for (aviso of ultimosAvisos.avisoMensajes; track aviso) {
                      <button mat-menu-item
                              onClick="return false;"
                              (click)="muestraAviso(aviso)"
                              [ngClass]="{'msg-read': aviso.leido === '1', 'msg-unread': aviso.leido !== '1'}">

                        <!-- color e icono -->
                        <span class="table-img msg-user">
                          <i class="material-icons-outlined nfc-type-icon"
                             [ngClass]="{
                                'nfc-blue': aviso.tipo === '1',
                                'nfc-green': aviso.tipo === '2',
                                'nfc-purple': aviso.tipo === '3'
                             }">
                            {{ aviso.tipo === '1' ? 'description' : aviso.tipo === '2' ? 'content_paste' : 'local_shipping' }}

                          </i>
                        </span>

                        <!-- mensaje y timestamp-->
                        <span class="menu-info">
                          <span class="menu-title">{{funcLib.limitarYAgregarPuntos(aviso.mensaje!, 50)}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i> {{aviso.created_at | friendlyDdmmyy }}
                          </span>
                        </span>

                        <!-- check de leído -->
                        <span *ngIf=" aviso.leido === '1'"
                              class="nfc-close">
                          <app-feather-icons [icon]="'check'"
                                             [class]="'user-menu-icons'"></app-feather-icons>
                        </span>

                      </button>
                      }
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <div (click)="marcarTodoLeido()"
                 class="nfc-footer">
              <a class="nfc-read-all">Marcar todos como leídos</a>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button
                  [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle"
                 ngbDropdownToggle
                 class="">
              <!-- <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User"> -->

              <img [src]="getUserFoto()"
                   alt="Usuario"
                   title="Usuario"
                   class="rounded-circle"
                   width="32"
                   height="32"
                   alt="User" />

            </div>
          </button>
          <mat-menu #profilemenu="matMenu"
                    class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">


                  <!-- 
                    <button mat-menu-item
                          class="user-item-list">
                    <app-feather-icons [icon]="'user'"                                       
                                       [class]="'user-menu-icons'"></app-feather-icons>Usuario
                  </button>

                  <button mat-menu-item
                          class="user-item-list">
                    <app-feather-icons [icon]="'mail'"
                                       [class]="'user-menu-icons'"></app-feather-icons>Avisos
                  </button> 
                -->



                  <button mat-menu-item
                          [routerLink]="['/config']"
                          class="user-item-list">
                    <app-feather-icons [icon]="'settings'"
                                       [class]="'user-menu-icons'"></app-feather-icons>Configuración
                  </button>

                  <button mat-menu-item
                          (click)="logOut()"
                          class="user-item-list">
                    <app-feather-icons [icon]="'log-out'"
                                       class=" text-danger"
                                       [class]="'user-menu-icons'"></app-feather-icons>Desconectar
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>