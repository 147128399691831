<!-- Modal para subir PDF's  -->

<div [class.oculto]="modalPdfSrv.ocultarModal"
     class="fondo-modal-imagen">
    <div class="modal fade show"
         id="exampleModal"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">
        <div class="modal-dialog"
             role="document">

            <div class="modal-content">
                <div class="modalHeader">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">Cargar PDF</h4>
                    <button mat-icon-button
                            (click)="cerrarModal()"
                            class="modal-close-button"
                            aria-label="Close dialog">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="modal-body">

                    <span *ngIf="loading">
                        <i class="fa fa-spin fa-refresh fa-2x mr-1"> </i><br>
                        Subiendo...
                    </span>

                    <div *ngIf=" modalPdfSrv.pdf &&  modalPdfSrv.pdf.endsWith('.pdf') "
                         class="text-center shadow p-2">

                        <h4>Actual: </h4>
                        <a href="{{modalPdfSrv.pdf}}"
                           target="_blank"
                           matTooltip="Abrir PDF del albarán">
                            <app-feather-icons [icon]="'external-link'"
                                               [class]="'tbl-fav-edit'">
                            </app-feather-icons>
                            Abrir pdf
                        </a>

                    </div>

                    <label class="custom-file d-block">

                        <div class="mb-3">
                            <span *ngIf=" modalPdfSrv.pdf &&  modalPdfSrv.pdf.endsWith('.pdf') ">
                                <label for="formFile"
                                       class="form-label mt-2"> Cambiar por:</label>
                            </span>

                            <input class="form-control form-control-lg"
                                   type="file"
                                   id="formFile"
                                   [disabled]="loading"
                                   (change)="subirPdf($event )"
                                   value=""
                                   accept=".pdf">
                        </div>
                    </label>


                </div>

                <div class="modal-footer d-flex">

                    <!-- <button type="button"
                            *ngIf="modalImagenSrv.foto && !modalImagenSrv.foto.includes('_noFoto.png')"
                            [disabled]="loading"
                            (click)="borrarFoto()"
                            class="mr-auto btn waves-effect waves-light btn-rounded btn-danger">Borrar foto</button> -->

                    <button (click)="cerrarModal()"
                            [disabled]="loading"
                            class="ml-auto btn waves-effect waves-light btn-rounded btn-secondary"
                            data-dismiss="modal">Cancelar</button>
                </div>

            </div>
        </div>
    </div>


</div>