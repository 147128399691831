<section class="content">
    <div class="container-fluid">
        @for (breadscrum of breadscrums; track breadscrum) {
        <div class="block-header">

            <!-- breadcrumb -->
            <app-breadcrumb [title]="breadscrum.title"
                            [items]="breadscrum.items"
                            [active_item]="breadscrum.active">
            </app-breadcrumb>
        </div>
        }


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="materialTableHeader">
                        <div class="left">
                            <ul class="header-buttons-left ms-0">
                                <li class="tbl-title">
                                    <h2>Artículos</h2>
                                </li>

                                <li class="tbl-search-box">
                                    <label for="search-input"><i class="material-icons search-icon">search</i></label>
                                    <input (keyup)="applyFilter($event)"
                                           [(ngModel)]="dataSource.filter"
                                           matInput
                                           placeholder="Buscar"
                                           type="search"
                                           #filter
                                           class="browser-default search-field"
                                           aria-label="Search box">
                                </li>

                            </ul>
                        </div>
                        <div class="right">
                            <ul class="tbl-export-btn">
                                <li class="tbl-header-btn"
                                    *ngIf="esAdmin()">
                                    <div class="m-l-10"
                                         matTooltip="Nuevo artículo">
                                        <button mat-mini-fab
                                                color="primary"
                                                (click)="openDialogNew()"
                                                [disabled]="loading">
                                            <mat-icon class="col-white">add</mat-icon>
                                        </button>
                                    </div>
                                </li>

                                <li class="tbl-header-btn">
                                    <div class="m-l-10"
                                         matTooltip="Recargar datos">
                                        <button mat-mini-fab
                                                (click)="getListaArticulos()"
                                                [disabled]="loading"
                                                color="primary">
                                            <mat-icon class="col-white">refresh</mat-icon>
                                        </button>
                                    </div>
                                </li>


                                <li class="tbl-header-btn"
                                    *ngIf="esAdmin()">
                                    <div class="m-l-10"
                                         matTooltip="Importar artíulos">
                                        <button mat-mini-fab
                                                (click)="openDialogImport()"
                                                color="primary"
                                                [disabled]="loading">
                                            <mat-icon class="col-white">upload</mat-icon>
                                        </button>
                                    </div>
                                </li>

                                <li>
                                    <div class="export-button m-l-10"
                                         matTooltip="Exportar a XLSX">
                                        <img src="assets/images/icons/xlsx.png"
                                             alt="Exportar a XLSX"
                                             (click)="exportExcel()" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>






                    <div class="body overflow-auto">
                        <div class="responsive_table">

                            <table mat-table
                                   [dataSource]="dataSource"
                                   matSort
                                   class="mat-elevation-z8 table table-hover">

                                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->



                                <!-- id -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Id </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        <div [innerHTML]="highlightSearch(rec.id, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> <b>Total</b> </td>
                                </ng-container>


                                <!-- foto -->
                                <ng-container matColumnDef="foto">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        [ngClass]="'tbl-col-width-per-6'">
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let row"
                                        matTooltip="Cambiar foto"
                                        (click)="$event.stopPropagation()"
                                        (click)="abrirModal(row)"
                                        class="table-img tbl-col-width-per-6">
                                        <span class="mobile-label">Image:</span>
                                        <img [src]="fotoDir +'/articulos' + imgResized +row.foto"
                                             loading="lazy" />
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>



                                <!-- sku -->
                                <ng-container matColumnDef="sku">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> SKU </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.sku, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>



                                <!-- ean -->
                                <ng-container matColumnDef="ean">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> EAN </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.ean, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- descripcion -->
                                <ng-container matColumnDef="descripcion">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Descripción </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.descripcion, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>



                                <!-- url -->
                                <ng-container matColumnDef="url">
                                    <th mat-header-cell
                                        class="pr-0"
                                        *matHeaderCellDef>Web</th>
                                    <td mat-cell
                                        *matCellDef="let row; let i=index;"
                                        class="pr-0 cdk-column-url"> <!-- Agrega la clase cdk-column-url para excluir de la búsqueda -->

                                        <span *ngIf="row.url">
                                            <a (click)="$event.stopPropagation()"
                                               href="{{ row.url }}"
                                               target="_blank">
                                                <button mat-icon-button
                                                        [disabled]="loading"
                                                        matTooltip="Abrir web"
                                                        class="tbl-action-btn">
                                                    <app-feather-icons [icon]="'link-2'"
                                                                       class="text-success"
                                                                       [class]="'tbl-fav-track'"></app-feather-icons>
                                                </button>
                                            </a>
                                        </span>

                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>




                                <!-- swLote -->
                                <ng-container matColumnDef="swLote">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Lotes </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <span *ngIf="rec.swLote === '1'">
                                            <mat-icon matTooltip="Gestiona lotes">done</mat-icon>
                                        </span>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>



                                <!-- artStock -->
                                <ng-container matColumnDef="artStock">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="text-end"> Stock </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        {{rec.artStock | number: '0.0-0':'es' }}
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef
                                        class="text-end"> <b>{{getTotalStock() | number: '0.0-0':'es' }}</b> </td>
                                </ng-container>



                                <!-- actions -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        class="pr-0"
                                        *matHeaderCellDef></th>
                                    <td mat-cell
                                        *matCellDef="let row; let i=index;"
                                        class="pr-0">

                                        <span *ngIf="esAdmin()">
                                            <span *ngIf=" row.artStock < 1">
                                                <button mat-icon-button
                                                        (click)="$event.stopPropagation()"
                                                        (click)="deleteRec(row)"
                                                        [disabled]="loading"
                                                        class="tbl-action-btn">
                                                    <app-feather-icons [icon]="'trash-2'"
                                                                       [class]="'tbl-fav-delete'"></app-feather-icons>
                                                </button>
                                            </span>
                                        </span>

                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>




                                <!-- Mostrar las columnas Cabecera/conenido/footer -->
                                <!-- Abrir detalle del rec -->

                                <tr mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>

                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumns;"
                                    (click)="openDialogArticuloDetall(row)"
                                    [style.cursor]="'pointer'"
                                    matRipple></tr>

                                <tr mat-footer-row
                                    *matFooterRowDef="displayedColumns"></tr>



                                <!-- Sin datos -->
                                <div *matNoDataRow
                                     class="no-results">
                                    <span *ngIf="!loading">
                                        Sin datos...
                                    </span>
                                </div>

                            </table>


                            <!-- Loading spinner -->
                            <div class="tbl-spinner-container"
                                 *ngIf="loading">
                                <mat-progress-spinner color="primary"
                                                      [diameter]="40"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>

                            <!-- Paginator -->
                            <div class="mt-3"></div>
                            <mat-paginator #paginator
                                           [length]="dataSource.filteredData.length"
                                           [pageIndex]="0"
                                           [pageSize]="10"
                                           [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>