import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { environment } from 'environments/environment';

// interfaces
import { IApiResp } from 'app/interfaces/api-resp';


@Injectable({
  providedIn: 'root'
})
export class DatosAuxService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
  ) { }

  getPais(id: number) {
    const path = `${this.apiURL}/paises/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaPaises() {
    const path = `${this.apiURL}/getListaPaises`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }

  getListaServicios() {
    const path = `${this.apiURL}/getListaServicios`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }



}
