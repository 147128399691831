import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/auth/material/material.module';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
  standalone: true,
  imports: [MaterialModule, FormsModule, MatButtonModule],
})
export class Page404Component {
  constructor(private router: Router) { }

  goHome() {
    this.router.navigate(['/']);
  }
}
