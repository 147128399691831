/* eslint-disable @typescript-eslint/no-unused-vars */

import { NotificacionesService } from 'app/services/notificaciones.service';
import { HttpInterceptorFn } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';


export const apiErrorInterceptor: HttpInterceptorFn = (req, next) => {

  return next(req)
    .pipe(catchError((error) => {

      // Error Unauthorized
      if ([401, 403].includes(error.status)) {
        console.log('__apiErrorInterceptor() Usuario NO autoriado')
        // UsuarioService.logOut()
      }

      const e = error.error.message || error.statusText
      console.log('__apiErrorInterceptor() error interceptoado: ', e)

      return throwError(() => error)
    }))


}; 
