// Módulos
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { of } from 'rxjs';


// Servicios
import { ModalImagenService } from '../modal-imagen.service';
import { NotificacionesService } from '../notificaciones.service';

// Modelos e Interfaces
import { environment } from 'environments/environment';
import { IUsuario } from 'app/interfaces/usuario'; 
import { RegistroUsuario } from 'app/interfaces/registro-usuario';
import { IApiResp } from 'app/interfaces/api-resp';



interface ApiResponse {
  data: {
    token: string;
    userdata: unknown; // Ajusta el tipo según la estructura real de userdata
  };
  error: unknown; // Ajusta el tipo según la estructura real de error
}


@Injectable({
  providedIn: 'root'
})

export class UsuarioService {
  apiURL: string = environment.apiURL;
  userdata: IUsuario = {}

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
    private modalImagenSrv: ModalImagenService
  ) { }

  ////////// AUTH  /////////



  logIn(formData: unknown) {
    localStorage.removeItem('token');
    const path = `${this.apiURL}/login`;
    return this.http.post<IApiResp>(path, formData)
      .pipe(
        catchError(error => {
          console.log('Error al intentar autenticar:', error);
          this.notificacionesSrv.aviso('error', 'Error al intentar autenticar, el servidor no está respondiendo. Por favor, intenta de nuevo más tarde.');
          return throwError(() => new Error(error));
        }),
        tap(resp => {
          if (resp.data.token) {
            this.userdata = resp.data.userdata;
            localStorage.setItem('token', resp.data.token);
          }
        })
      );
  }


  logOut() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }



  validarToken(): Observable<boolean> {
    const token = localStorage.getItem('token') || '';
    const cabeceras = new HttpHeaders().append(
      'Authorization', 'Bearer ' + token
    );

    console.log(`__validarToken(${token})`);

    return this.http.get<ApiResponse>(`${this.apiURL}/refreshToken`, {
      headers: cabeceras
    }).pipe(
      catchError(error => {
        console.log('Error al intentar acceder al servidor:', error);
        this.notificacionesSrv.aviso('error', `El servidor ${this.apiURL} no está respondiendo. Por favor, intenta de nuevo más tarde.`);
        this.logOut();
        return throwError(() => new Error(error));
      }),
      tap(resp => {
        if (resp.data.token) {
          // guardamos el nuevo token  
          localStorage.setItem('token', resp.data.token);
          this.userdata = resp.data.userdata!;

          console.log('__validarToken() userdata:', this.userdata);

        }
      }),
      map(resp => {
        // devolver true solo si la API no devuelve error
        return !resp.error;
      })
    );
  }




  crearUsuario(formData: RegistroUsuario) {
    const path = `${this.apiURL}/register`;
    return this.http.post<IApiResp>(path, formData);
  }

  getUserDetall(id_usr: number) {
    // Devulve un registro de usr y si existe los datos del cliente asociado
    const path = `${this.apiURL}/usrList/${id_usr}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  //////  GET usr data  ///////
  getUsr(): Observable<unknown> {
    this.validarToken();
    console.log('desde el servicio this.userdata:', this.userdata);
    return of(this.userdata);
  }

  getProfile(): Observable<unknown> {
    // Devulve la info del Token
    const path = `${this.apiURL}/profile`;
    return this.http.get<IApiResp>(path);
  }





  getUserFoto() {
    // Devuleve la foto del usuario
    let foto = this.userdata.foto || null;
    if (foto) {
      foto = `${environment.fotoDir}/users/${foto}`;
    } else {
      foto = `${environment.fotoDir}/users/_noFoto.png`;
    }
    return (foto);
  }


  getLista() {
    // Devulve la lista de usuarios
    const path = `${this.apiURL}/usrList`;
    const lista = this.http.get<IApiResp>(path)
      .pipe(
      // delay(10)
    );
    return lista;
  }


  getUsrListRol(params: unknown) {
    // Devulve usuarios con el rol x del almacén y
    const path = `${this.apiURL}/usrListRol`;
    return this.http.post<IApiResp>(path, params)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }




  // CRUD
  actualizaUsr(usuario: Usuario) {
    console.log('__ServicioUsr:', usuario)
    const path = `${this.apiURL}/userUpdate/${this.userdata.id}`;
    return this.http.post<IApiResp>(path, usuario)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  deleteUsr(id: number) {

    const path = `${this.apiURL}/userDelete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }




  ///// AUX ////

  errorAuth() {
    this.logOut();
    this.modalImagenSrv.cerrarModal();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }

}
