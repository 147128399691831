/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';
import { environment } from 'environments/environment';
import { IEntrada, IEntradaDetallMEdidas } from 'app/interfaces/entrada';
import { IApiResp } from 'app/interfaces/api-resp';
import { IListaOpciones } from 'app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {

  apiURL: string = environment.apiURL;

  public entrada: IEntrada = {};
  public selected: any;    // Línea de la entrada seleccionada

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getEntrada(id: number) {
    const path = `${this.apiURL}/entradas/list/${id}`;
    const resp = this.http.get<IApiResp>(path)
    return resp;
  }


  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/entradas/listFiltro`;

    const data = {
      id: _opciones.id || null,
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null,
      swCompleto: _opciones.swCompleto || null,
      swValidado: _opciones.swValidado || null,
    };

    return this.http.post<IApiResp>(path, data)

  }



  new(formData: IEntrada) {
    const path = `${this.apiURL}/entradas/new`;
    return this.http.post<IApiResp>(path, formData)
  }

  update(entrada: IEntrada) {
    const path = `${this.apiURL}/entradas/update/${entrada.id}`;

    return this.http.post<IApiResp>(path, entrada)
  }


  updateMedidas(datos: IEntradaDetallMEdidas) {
    const path = `${this.apiURL}/entradas/updateMedidas`;

    return this.http.post<IApiResp>(path, datos)
  }


  delete(id: number) {
    const path = `${this.apiURL}/entradas/delete/${id}`;
    return this.http.get<IApiResp>(path)
  }

  ///// AUX ////


}
