import { Route } from '@angular/router';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { AuthGuard } from '@core/guard/auth.guard';

// Componentes
import { Page404Component } from './authentication/page404/page404.component';
import { PalantillaAuthComponent } from './auth/palantilla-auth/palantilla-auth.component';
import { ConfigComponent } from '@config/config.component';
import { StockComponent } from './stock/stock.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { LoginComponent } from './auth/login/login.component';
import { PedidosExtComponent } from './pedidos-ext/pedidos-ext.component';
import { ArticulosComponent } from './articulos/articulos.component';
import { EntradasComponent } from './entradas/entradas.component'; 
import { EntradasValComponent } from './entradas-val/entradas-val.component';


export const APP_ROUTE: Route[] = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.routes').then((m) => m.DASHBOARD_ROUTE),
      },
      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.routes').then(
            (m) => m.ADVANCE_TABLE_ROUTE
          ),
      },

      { path: 'config', title: 'InovaCen | Config', component: ConfigComponent },
      { path: 'stock', title: 'InovaCen | Stock', component: StockComponent },

      { path: 'pedidos', title: 'InovaCen | Pedidos', component: PedidosComponent },
      { path: 'pedidos/:buscar', title: 'InovaCen | Pedidos', component: PedidosComponent },

      { path: 'solicitud-pedidos', title: 'InovaCen | Pedidos', component: PedidosExtComponent },

      { path: 'articulos', title: 'InovaCen | Artículos', component: ArticulosComponent },
      
      { path: 'entradas', title: 'InovaCen | Entradas', component: EntradasComponent },
      { path: 'entradas-validadas', title: 'InovaCen | Entradas Validadas', component: EntradasValComponent },
      { path: 'entradas-validadas/:buscar', title: 'InovaCen | Entradas Validadas', component: EntradasValComponent },


      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.routes').then(
            (m) => m.EXTRA_PAGES_ROUTE
          ),
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.routes').then(
            (m) => m.MULTILEVEL_ROUTE
          ),
      },
    ],
  },


  // {
  //   path: 'authentication',
  //   component: AuthLayoutComponent,
  //   loadChildren: () =>
  //     import('./authentication/auth.routes').then((m) => m.AUTH_ROUTE),
  // },



  { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },

  // Auth
  {
    path: 'auth', component: PalantillaAuthComponent, children: [
      { path: '**', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', title: 'InnovaCen | LogIn', component: LoginComponent },
    ]
  },


  { path: '**', component: Page404Component },
];
