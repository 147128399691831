import { Injectable } from '@angular/core';

// Servicio para convertir texto a audio

@Injectable({
  providedIn: 'root'
})
export class HablaService {

  constructor() { }

  habla(txtVoz: string): void {
    // Lee un texto
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(this.quitarEtiquetasHTML(txtVoz));
    synth.speak(utterance);
    
    console.log('__habla:', this.quitarEtiquetasHTML(txtVoz))
  }

  quitarEtiquetasHTML(textoHTML: string): string {
    return textoHTML.replace(/<[^>]*>/g, '');
  }

}
