/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Módulos
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

// Servicios
import { UsuarioService } from './usuario.service';
import { NotificacionesService } from '../notificaciones.service';


// Interfaces / Entorno
import { environment } from 'environments/environment';
import { IApiResp } from "app/interfaces/api-resp";
import { IListaOpciones } from 'app/interfaces/lista-opciones';

@Injectable({
  providedIn: 'root'
})
export class PedidosExternosService {

  apiURL: string = environment.apiURL;
  public selected: any;                   // Línea seleccionada

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }


  getPedido(id: number) {
    const path = `${this.apiURL}/pedidosExternos/list/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getPedidoEDetall(id: number) {
    const path = `${this.apiURL}/pedidosExternos/getPedidoEDetall/${id}`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getLista(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/pedidosExternos/listFiltro`;
    return this.http.post<IApiResp>(path, _opciones)
  }


  new(data: any) {
    const path = `${this.apiURL}/pedidosExternos/new`;
    return this.http.post<IApiResp>(path, data)
  }


  delete(id: number) {
    const path = `${this.apiURL}/pedidosExternos/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
