import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale'; // Importa la configuración local para español


@Pipe({
  name: 'friendlyTimestamp',
  standalone: true
})
export class FriendlyTimestampPipe implements PipeTransform {

  transform(value: number): string {
    // Pasar del time() de PHP al time de js
    return formatDistanceToNow(new Date(value * 1000), { locale: es });
  }

}
