/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// módulos
import { MaterialModule } from 'app/auth/material/material.module';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

// interfaces
import { IApiDataPedido } from 'app/pedidos/iDataPedido';
import { IEmail } from 'app/interfaces/email';

// servicios
import { UsuarioService } from 'app/services/datos/usuario.service';
import { PedidosService } from 'app/services/datos/pedidos.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { EmailService } from 'app/services/mail.service';

// pipes y librerías
import { PedidoEstadoPipe } from "../../../pipes/pedido-estado.pipe";
import { AccionClientePipe } from "../../../pipes/accion-cliente.pipe";

import { environment } from 'environments/environment';
import Swal from 'sweetalert2';


export interface IDialogData {
  id: number;
  action: string;
  pedido: IApiDataPedido;
}

@Component({
  selector: 'app-pedido-detall-dialog',
  templateUrl: './pedido-detall-dialog.component.html',
  styleUrls: ['./pedido-detall-dialog.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  standalone: true,
  imports: [
    MaterialModule, MatDialogContent, MatFormFieldModule, MatInputModule, MatRadioModule,
    MatDialogClose, CommonModule, PedidoEstadoPipe, FormsModule,
    AccionClientePipe
  ]
})
export class PedidoDetallDialogComponent {

  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  dialogTitle: string = '';
  public pedido: any = {};
  public selectedAccionEspecial: string = '0';

  public loading: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public userSrv: UsuarioService,
    public dialogRef: MatDialogRef<PedidoDetallDialogComponent>,
    private notificacionSrv: NotificacionesService,
    private pedidosSrv: PedidosService,
    private eMailSrv: EmailService
  ) {

    console.log('__FormDialogComponent data: ', data)
    this.pedido = data.pedido;

    this.dialogTitle = 'Pedido ' + this.pedido.pedido_cli
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  accionEspecial(pedido: IApiDataPedido) {
    // El pedido NO va a salir, confirmar
    const accion = this.selectedAccionEspecial
    console.log('selectedAccionEspecial: ', accion)
    if (accion === '0') return;

    let titulo: string = '';
    let texto: string = '';

    if (accion === '1') {
      titulo = 'Cancelar pedido'
      texto = `<b>¿Quieres cancelar este pedido?</b><br> 
              <small>
                El pedido quedará marcado como anulado
              </small><br><br>
              <small>
                <b>Atención:</b> Una vez solicitado ya no se podrá cambiar.
              </small>`
    }

    if (accion === '2') {
      titulo = 'Servir con faltas'
      texto = `<b>¿Quieres enviar el pedido tal como está?</b><br> 
              <small>
                El pedido se enviará solo con los artículos preparados
              </small><br><br>
              <small>
                <b>Atención:</b> Una vez solicitado ya no se podrá cambiar.
              </small>`
    }
    Swal.fire({
      title: titulo,
      icon: 'warning',
      html: texto,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {

        // Modificar pedido: accion_cliente=_accion, accion_cliente_fecha=NOW(), aviso_cliente=NULL
        // Enviar email al cliente y al administrador

        const datos = {
          ...this.pedido,
          _accion: accion
        }

        console.log('__solicitaAccion() datos', datos)

        this.loading = true;
        this.pedidosSrv.solicitaAccion(datos).subscribe(resp => {
          this.loading = false;

          console.log('__solicitaAccion() resp', resp)

          if (resp.error) {
            this.notificacionSrv.aviso('error', resp.mensaje)
            this.selectedAccionEspecial = '0'
            return
          }

        })

        this.pedido.accion_cliente = accion
        this.pedido.accion_cliente_fecha = new Date()
        this.pedido.aviso_cliente = null

        this.enviaEmails(datos)

        this.notificacionSrv.aviso('info', `Se procesará la acción especial sobre el pedido ${pedido.pedido_cli}, te hemos enviado un eMail de confirmación`)
      }
      this.selectedAccionEspecial = '0'
    })

  }

  enviaEmails(datos: any) {
    // Enviar email al cliente y al administrador

    const timestamp: number = new Date().getTime();
    const accionClientePipe = new AccionClientePipe();
    const valorAccionTransformado = accionClientePipe.transform(datos._accion);

    // cliente
    const dataCli: IEmail = {
      'toEmail': this.userSrv.userdata.email!,
      'subject': `Solicitud de accion especial sobre el pedido ${datos.pedido_cli} de ${datos.cliNombre} - ${timestamp}`,
      'body': `eMail al usuario<br> ${datos.cliNombre} solicita acción especial <b>${valorAccionTransformado}</b> sobre el pedido ${datos.pedido_cli} de la campaña  ${datos.camp_cod}<br> `
    }

    this.loading = true;
    this.eMailSrv.enviaEmail(dataCli).subscribe(resp => {
      this.loading = false;
      console.log('enviaEmail()', resp);

      if (resp.error) {
        this.notificacionSrv.aviso('error', resp.mensaje);
        return
      }

    })


    // administrador
    if (!this.userSrv.userdata.cliente?.email_intranet) {
      this.notificacionSrv.aviso('error', 'No existe ningún eMail configurado para el envío de solicitudes al administrador');
      return
    }
    const dataAdmin: IEmail = {
      'toEmail': this.userSrv.userdata.cliente?.email_intranet!,
      'subject': `Solicitud de accion especial sobre el pedido ${datos.pedido_cli} de ${datos.cliNombre} - ${timestamp}`,
      'body': `eMail al admin <br> ${datos.cliNombre} solicita acción especial <b>${valorAccionTransformado}</b> sobre el pedido ${datos.pedido_cli} de la campaña  ${datos.camp_cod}<br> `
    }

    this.loading = true;
    this.eMailSrv.enviaEmail(dataAdmin).subscribe(resp => {
      this.loading = false;
      console.log('enviaEmail()', resp);

      if (resp.error) {
        this.notificacionSrv.aviso('error', resp.mensaje);
        return
      }

    })


  }


}


