/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

// constantes
import * as Const from '../../../shared/constants';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

// módulos
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MaterialModule } from 'app/auth/material/material.module';

//interfaces
import { IApiArticulo } from 'app/articulos/IApiArticulo';
import { IArticulo } from 'app/interfaces/articulo';


//servicios
import { DatosAuxService } from 'app/services/datos/datosAux.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { ArticulosService } from 'app/services/datos/articulos.service';
import { UsuarioService } from './../../../services/datos/usuario.service';
import { ModalImagenService } from 'app/services/modal-imagen.service';


//librerías
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { FeatherIconsComponent } from "../../../shared/components/feather-icons/feather-icons.component";
import Swal from 'sweetalert2';

import CustomVal from 'app/providers/CustomValidators';
import { environment } from 'environments/environment';
import { Observable, Subscription, delay } from 'rxjs';




export interface IDialogData {
  id: number;
  action: string;
  articulo: IApiArticulo;   // Artículo vitaminado, en formato API
}


@Component({
  selector: 'app-articulo-dialog',
  templateUrl: './articulo-dialog.component.html',
  styleUrl: './articulo-dialog.component.scss',
  standalone: true,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    provideNgxMask()
  ],
  imports: [CommonModule, MaterialModule, MatAutocompleteModule, MatIconModule, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule,
    MatRadioModule, MatDatepickerModule, MatSelectModule, MatOptionModule, MatDialogClose, NgxMaskDirective, NgxMatSelectSearchModule,
    NgSelectModule, NgOptionHighlightModule, FeatherIconsComponent]
})
export class ArticuloDialogComponent implements OnInit {

  @ViewChild('articuloForm') articuloForm: NgForm | undefined;

  public action: string;
  private id_cliente: number | null = null

  dialogTitle: string;
  txtSubmit: string = 'Modificar';


  public articulo: IApiArticulo = {};
  public articForm: FormGroup = new FormGroup({});

  public fotoDir: string = environment.fotoDir;
  public imgSubs: Subscription | undefined;

  public loading: boolean = false;
  public enviado: boolean = false;


  // Definir un EventEmitter para emitir el resultado
  resultEmitter: EventEmitter<any> = new EventEmitter();


  constructor(
    public dialogRef: MatDialogRef<ArticuloDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private fb: FormBuilder,
    public userSrv: UsuarioService,
    public datosAuxSrv: DatosAuxService,
    private notificSrv: NotificacionesService,
    private articulosSrv: ArticulosService,
    private modalImagenSrv: ModalImagenService

  ) {
    // Set Valores por defecto
    this.id_cliente = this.userSrv.userdata.cliente!.id;
    this.action = data.action;

    if (this.action === 'edit') {
      this.dialogTitle = 'Modificar artículo ' + data.articulo.ean!;
      this.txtSubmit = 'Modificar';
      this.articulo = data.articulo;
    } else {
      this.dialogTitle = 'Nuevo artículo';
      this.txtSubmit = 'Crear';
      this.articulo = {
        id_cliente: this.id_cliente,
        swLote: '0',
        artStock: 0,
        origen: '1',
        swDefectuoso: Const.NO_DEFECTUOSO,
        foto: '_noFoto.png'
      }
    }

  }


  ngOnInit(): void {

    // Nos subscribimos a los cambios de la imagen de modalImagenSrv 
    this.imgSubs = this.modalImagenSrv.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => {
        this.articulo.foto = img.guardado_como;
        this.articForm.patchValue({
          foto: img.guardado_como
        });
      });



    // Definir formulario

    this.articForm = this.fb.group({
      id_cliente: [''],
      swLote: [''],
      ean: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      sku: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      descripcion: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(255)]],
      foto: [''],
      url: [''],
      unidadesCaja: [''],
      alto: ['', [Validators.pattern(/^\d+$/)]],
      ancho: ['', [Validators.pattern(/^\d+$/)]],
      largo: ['', [Validators.pattern(/^\d+$/)]],
      peso: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      origen: [''],
      artStock: [''],
    });

    this.cargaDatosArticulo();
  }


  cargaDatosArticulo() {
    // Carga los datos del artículo en el formulario

    console.log('__cargaDatosArticulo()', this.articulo)

    if (this.articulo.swLote !== '1') this.articulo.swLote = '0';

    this.articForm.patchValue({
      id_cliente: this.articulo.id_cliente,
      swLote: this.articulo.swLote,
      ean: this.articulo.ean,
      sku: this.articulo.sku,
      descripcion: this.articulo.descripcion,
      foto: this.articulo.foto,
      url: this.articulo.url,
      unidadesCaja: this.articulo.unidadesCaja,
      alto: this.articulo.alto,
      ancho: this.articulo.ancho,
      largo: this.articulo.largo,
      peso: this.articulo.peso,
      origen: this.articulo.origen,
      artStock: this.articulo.artStock,
    })

    this.calcVolumen()
  }


  // CRUD

  enviar() {
    this.enviado = true;

    // Validar form  
    if (this.articForm.invalid) {
      console.log("ERRORES en articForm:", this.articForm);
      return;
    }

    //Enviar datos al Back (new/update)
    const formData = this.articForm.value;
    formData.swActivo = 1;
    formData.swDefectuoso = Const.NO_DEFECTUOSO;
    if (this.articulo.id) formData.id = this.articulo.id;

    console.log("__enviar() ", formData);


    // Update ó New artículo

    let apiEndpoint: Observable<any>;

    if (this.action === 'new') {
      apiEndpoint = this.articulosSrv.newRec(formData);
    } else {
      apiEndpoint = this.articulosSrv.updateRec(formData);
    }

    this.loading = true;
    apiEndpoint.subscribe(resp => {
      this.loading = false;

      console.log(`__${apiEndpoint} resp`, resp);

      if (resp.error) {

        // Marcar los campos con error
        for (const controlName in resp.mensaje) {
          const control = this.f[controlName];
          if (control) control.setErrors({ 'apiError': resp.mensaje[controlName] });
        }

      } else {
        if (this.action === 'new') this.notificSrv.aviso('success', `Artículo ${resp.data.ean} creado correctamente con el <b>id: ${resp.data.id}</b>`);
        if (this.action === 'edit') this.notificSrv.aviso('success', `Artículo ${this.articulo.ean}  modificado correctamente.`);

        // Emitir resp y cerrar dialogo    
        this.resultEmitter.emit(resp);
        this.dialogRef.close();
        return
      }
    })
  }




  cancelarClick() {
    this.dialogRef.close();
    return
  }



  //// AUX    /// 

  get f() {
    return this.articForm.controls;
  }


  abrirModal(articulo: IApiArticulo) {
    if (this.loading) return
    this.modalImagenSrv.abrirModal('articulos', articulo.id?.toString() || '', articulo.foto);
  }



  toUpper(fieldName: string) {
    const formControl = this.articForm.get(fieldName);

    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.toUpperCase());
    }
  }

  calcVolumen() {
    const alto = this.f['alto'].value / 100;
    const largo = this.f['largo'].value / 100;
    const ancho = this.f['ancho'].value / 100;

    this.articulo.volumen = (alto * largo * ancho);
  }


} 