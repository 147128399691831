/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';

// componentes 
import { BreadcrumbComponent } from "../shared/components/breadcrumb/breadcrumb.component";

// módulos  
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MaterialModule } from 'app/auth/material/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

// interfaces 
import { IListaOpciones } from 'app/interfaces/lista-opciones';

// servicios
import { PedidosExternosService } from 'app/services/datos/pedidosExternos.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { AlmacenesService } from 'app/services/datos/almacenes.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerías
import { TableElement, TableExportUtil, UnsubscribeOnDestroyAdapter } from '@shared';
import { PedidoEstadoPipe } from 'app/pipes/pedido-estado.pipe';
import { MatDialog } from '@angular/material/dialog';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { IDataPedidoExterno } from './IDataPedidoExterno';
import { FormExtDialogComponent } from './dialogs/form-ext-dialog/form-ext-dialog.component';

@Component({
  selector: 'app-pedidos-ext',
  standalone: true,
  templateUrl: './pedidos-ext.component.html',
  styleUrl: './pedidos-ext.component.scss',
  providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es' }],
  imports: [CommonModule, FormsModule, BreadcrumbComponent, MaterialModule, MatTableModule, FeatherIconsComponent,
    MatSortModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, PedidoEstadoPipe]
})
export class PedidosExtComponent extends UnsubscribeOnDestroyAdapter {

  // Decoradores para ordenar y paginación
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  private pdfDir: string = environment.pdfDir;

  breadscrums = [{ title: 'Solicitud de Pedidos', items: ['Inicio'], active: 'Solicitud de Pedidos', }];
  loading: boolean = false;
  opcionesList: IListaOpciones = {}

  displayedColumns: string[] = ['id', 'pedido_cli', 'created_at', 'tot_articulos', 'tot_referencias', 'nombre', 'cpPoblacion', 'pais_iso', 'cod_servicio', 'actions'];
  dataSource = new MatTableDataSource<IDataPedidoExterno>([]);


  constructor(
    private pedidosExtSrv: PedidosExternosService,
    public almacenesSrv: AlmacenesService,
    public usuarioSrv: UsuarioService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.getListaPedidos();
  }


  getListaPedidos() {
    this.opcionesList.id_cliente = this.usuarioSrv.userdata.cliente!.id;
    this.opcionesList.camp_cod_null = '1';

    this.loading = true;
    this.pedidosExtSrv.getLista(this.opcionesList)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.dataSource.data = resp.data;
        console.log('__getListaPedidos() ', resp)
      })
  }


  // Buscar
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  highlightSearch(text: string, searchTerm: string): string {

    if (text == null) return '';
    if (!searchTerm) return text;

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, match => `<span class="resaltado">${match}</span>`);
    return highlightedText;
  }


  transformDate(date: string | null): string {
    //  return date !== null ? this.datePipe.transform(date, 'shortDate') ?? '' : '';    
    if (!date) return '';
    return date !== null ? this.datePipe.transform(date, 'yyyy-MM-dd') ?? '' : '';
  }



  // Totales
  getTotalReferencias(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_referencias! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);
  }

  getTotalArticulos(): number {
    return this.dataSource.filteredData.reduce((total, rec) => {
      const cantidadNum = rec.tot_articulos! || 0;      // Convertir la cantidad a un número antes de sumar
      return total + cantidadNum;
    }, 0);

  }



  //  Diálogos CRUD  //


  // Editar 
  openDialogPedidoDetall(row: IDataPedidoExterno) {
    console.log('__openDialogPedidoDetall()', row)
    if (!this.esAdmin()) return;

    const dialogRef = this.dialog.open(FormExtDialogComponent, {
      data: {
        pedido: row,
        action: 'edit',
      }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      // After dialog is closed 
      console.log('---> Estoy en pedidos-ext.component, se acaba de cerrar el modal action: -->EDIT<-- result:', result)
      this.getListaPedidos();
    });
  }


  // Añadir
  openDialogNew() {
    const dialogRef = this.dialog.open(FormExtDialogComponent, {
      data: {
        action: 'new',
      }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      // After dialog is closed 
      console.log('---> Estoy en pedidos-ext.component, se acaba de cerrar el modal action: -->NEW<-- result:', result)
      this.getListaPedidos();
    });
  }


  // editRec(row: IDataPedidoExterno) {
  //   // Botón editRec
  //   console.log('__editRec() ', row)

  //   const dialogRef = this.dialog.open(FormExtDialogComponent, {
  //     data: {
  //       pedido: row,
  //       action: 'edit',
  //     }
  //   });
  //   this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
  //     // After dialog is closed 
  //     console.log('---> Estoy en pedidos-ext.component, se acaba de cerrar el modal action: -->EDIT<-- result:', result)
  //     this.getListaPedidos();
  //   });
  // }


  // Borrar
  deleteRec(pedido: IDataPedidoExterno) {
    console.log('__deleteRec() ', pedido)

    Swal.fire({
      title: 'Borrar pedido',
      icon: 'warning',
      html: `¿Quieres eliminar el pedido ${pedido.pedido_cli}?`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pedidosExtSrv.delete(parseInt(pedido.id!)).subscribe(resp => {
          console.log('pedidosExtSrv.delete', resp)
          if (resp.error) {
            this.notificSrv.aviso('error', resp.mensaje)
            return
          }

          this.notificSrv.aviso('success', `Pedido ${pedido.pedido_cli} eliminado`)
          this.getListaPedidos();
          return
        })

      }
    })

  }


  /// AUX /// 




  // export table data in excel file
  exportExcel() {
    const exportData: Partial<TableElement>[] =
      this.dataSource.filteredData.map((x) => ({
        'Id': x.id,
        'Pedido': x.pedido_cli,
        'Total artículos': x.tot_articulos,
        'Total referencias': x.tot_referencias,

        'Dirección': x.direccion,
        'cp': x.cp,
        'Población': x.poblacion,
        'País': x.pais_iso,

        'Nombre': x.nombre,
        'eMail': x.email,
        'Teléfono': x.telefono,

        'Servicio': x.cod_servicio,
        'Contrarrembolso': x.contrarrembolso

      }));

    console.log('exportData', exportData)
    if (!exportData.length) {
      this.notificSrv.aviso('warning', 'No hay datos a exportar en XLSX')
      return
    }
    TableExportUtil.exportToExcel(exportData, 'excel');

  }


  // Devuelve Si el usuario actual es administrador
  esAdmin(): boolean {
    return this.usuarioSrv.userdata.rolIntranet === 'admin';
  }



}