/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CommonModule, DOCUMENT, NgClass } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2, HostListener, } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { InConfiguration, WINDOW, RightSidebarService } from '@core';
import { NgScrollbar } from 'ngx-scrollbar';
import { FeatherIconsComponent } from '../../shared/components/feather-icons/feather-icons.component';

// módulos
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from 'app/auth/material/material.module';

// servicios
import { ConfigService } from '@config';
import { WINDOW_PROVIDERS } from '@core/service/window.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { AvisosService } from 'app/services/datos/avisos.service';

// interfaces 
import { AvisoMensaje, IAvisos } from 'app/interfaces/avisos';

// librerías y pipes
import { FriendlyDdmmyyPipe } from 'app/pipes/friendly-ddmmyy.pipe';
import FuncLib from 'app/providers/funcionesLib';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { ModalImagenService } from 'app/services/modal-imagen.service';
import { environment } from 'environments/environment';


// interface Notifications {
//   message: string;
//   time: string;
//   icon: string;
//   color: string;
//   status: string;
// }


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  providers: [RightSidebarService, WINDOW_PROVIDERS],
  imports: [MaterialModule, CommonModule, RouterLink, NgClass, MatButtonModule, FeatherIconsComponent,
    MatMenuModule, NgScrollbar, FriendlyDdmmyyPipe]
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  public config!: InConfiguration;

  entorno: string = environment.entorno;

  isNavbarCollapsed = true;
  isNavbarShow = true;
  flagvalue: string | string[] | undefined;
  countryName: string | string[] = [];
  langStoreValue?: string;
  defaultFlag?: string;
  isOpenSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;

  public funcLib: FuncLib = new FuncLib();
  public showRedDot: boolean = true;
  public loading: boolean = false;

  public ultimosAvisos: IAvisos = {};
  public avisosNoLeidos: number = 0;
  public avisosTitulo: string = 'Todo leído';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private router: Router,
    public usuarioSrv: UsuarioService,
    public avisosSrv: AvisosService,
    private notifSrv: NotificacionesService,
    private modalImagenSrv: ModalImagenService
  ) {
    super();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    // if (offset > 50) {
    //   this.isNavbarShow = true;
    // } else {
    //   this.isNavbarShow = false;
    // }
  }
  ngOnInit() {
    this.config = this.configService.configData;
    this.docElement = document.documentElement;
    this.ultimosAvisos = this.usuarioSrv.userdata.ultimosAvisos || {};
    this.avisosNoLeidos = this.ultimosAvisos.avisoTotales?.totalNoLeidos || 0;

    if (this.avisosNoLeidos) this.avisosTitulo = `Tienes ${this.avisosNoLeidos} aviso${this.avisosNoLeidos !== 1 ? 's' : ''} sin leer`;


    console.log('headerComponent onInit() this.usuarioSrv.userdata ', this.usuarioSrv.userdata)
  }

  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }


  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }

  logOut() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/auth');
  }


  getUserFoto() {
    return (this.usuarioSrv.getUserFoto());
  }


  // Avisos // 
  muestraAviso(aviso: AvisoMensaje) {
    const fechaEs = new Date(aviso.created_at);
    const fechaFormateada = format(fechaEs, 'dd/MM/yyyy HH:mm');
    let titulo: string = 'Mensaje'
    let icono: string = 'description'
    let irA: string = 'pedidod'

    if (aviso.tipo === '2') {
      titulo = 'Info pedido'
      icono = 'content_paste'
      irA = 'pedidos'
    }

    if (aviso.tipo === '3') {
      titulo = 'Info entrada'
      icono = 'local_shipping'
      irA = 'entradas-validadas'
    }
    const iconoHtml = '<i class="material-icons-outlined nfc-type-icon">' + icono + '</i>'

    Swal.fire({
      title: `${iconoHtml} ${titulo}`,
      html: `${aviso.mensaje}<br>
      <small class="text-muted">${fechaFormateada} </small> `,
      confirmButtonText: 'Cerrar',
      showCancelButton: aviso.buscar_pedido ? true : false, // Mostrar el botón de Cancelar solo si hay algo en aviso.buscar_pedido
      cancelButtonText: `Buscar`,
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        console.log(`Se hizo clic en "Buscar Pedido" ${aviso}`);
        this.router.navigateByUrl(`/${irA}/${aviso.buscar_pedido}`);
      }
    });


    // marcar como leído
    this.loading = true
    this.avisosSrv.marcaLeido(aviso).subscribe(resp => {
      this.loading = false
      if (resp.error) {
        this.notifSrv.aviso('error', resp.mensaje)
        return
      }
      this.recuperarUserData()
    })
  }

  marcarTodoLeido() {
    // Marca todos los avisos como leidos

    if (!this.avisosNoLeidos) {
      this.notifSrv.aviso('info', 'No tienes avisos pendientes')
      return
    }

    Swal.fire({
      title: `Marcar avisos como leídos`,
      icon: 'question',
      html: `¿Quieres marcar los ${this.avisosNoLeidos} avisos no leidos como leídos?`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {

        this.loading = true
        this.avisosSrv.marcarTodoLeido(this.usuarioSrv.userdata.cliente!.id).subscribe(resp => {

          console.log('__marcarTodoLeido()', resp)

          this.loading = false
          if (resp.error) {
            this.notifSrv.aviso('error', resp.mensaje)
            return
          }
          this.notifSrv.aviso('success', resp.mensaje)
          this.recuperarUserData()
        })
      }

    })

  }


  recuperarUserData() {
    // Recuperar userdata con la nueva lista de avisos
    this.loading = true
    this.usuarioSrv.getUserDetall(this.usuarioSrv.userdata.id!).subscribe(resp => {
      this.loading = false
      console.log('__getUserDetall() tras borrar', resp)
      if (resp.error) {
        this.notifSrv.aviso('error', resp.mensaje)
        return
      }
      this.usuarioSrv.userdata = resp.data

      // iniciar valores
      this.ultimosAvisos = this.usuarioSrv.userdata.ultimosAvisos || {};
      this.avisosNoLeidos = this.ultimosAvisos.avisoTotales?.totalNoLeidos || 0;

      this.avisosTitulo = 'Todo leído';
      if (this.avisosNoLeidos) this.avisosTitulo = `Tienes ${this.avisosNoLeidos} aviso${this.avisosNoLeidos !== 1 ? 's' : ''} sin leer`;

    })
  }




}
