<div class="addContainer">

    <div class="modalHeader">

        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <img src='../../../../assets/images/trello.svg'
                     alt="Artículo InnovaCen">

                <div class="modal-about">
                    <span class="text-success me-2"> {{dialogTitle}}</span>
                    <small>{{articulo.created_at | date: 'MM/dd/yyyy'}}</small>
                </div>
            </div>
        </div>

        <div *ngIf="action === 'edit'"
             class="cabeceraDiv border rounded p-1 ms-3 mt-2">
            <div class="ms-1">
                <span class="ml-1 text-success">Stock: </span>
                <span class="ml-1">{{ f['artStock'].value | number: '0.0-0':'es' }}</span>
            </div>
        </div>



        <button mat-icon-button
                (click)="cancelarClick()"
                class="modal-close-button"
                aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <form class="m-1"
              [formGroup]="articForm"
              #formularioCabecera
              (ngSubmit)="enviar()">

            <div class="row">

                <!-- ean -->
                <div class="col-3">
                    <mat-form-field class="example-full-width mb-1"
                                    appearance="outline">
                        <mat-label>EAN</mat-label>
                        <input matInput
                               type="text"
                               [readonly]="action !== 'new'"
                               [class.noEditable]="action !== 'new'"
                               formControlName="ean"
                               (blur)="toUpper('ean')"
                               maxlength="30">
                        <mat-error *ngIf="f['ean'].hasError('required')">Oligatorio</mat-error>
                        <mat-error *ngIf="f['ean'].hasError('minlength')">Mínimo 4 caracteres</mat-error>
                        <mat-error *ngIf="f['ean'].hasError('maxLength')">Máximo 30 caracteres</mat-error>


                        <mat-error *ngIf="articForm.get('ean')?.errors?.['apiError']">{{
                            articForm.get('ean')?.errors?.['apiError'] }}</mat-error>

                    </mat-form-field>
                </div>

                <!-- sku -->
                <div class="col-3">
                    <mat-form-field class="example-full-width mb-1"
                                    appearance="outline">
                        <mat-label>Referencia</mat-label>
                        <input matInput
                               [readonly]="action !== 'new'"
                               [class.noEditable]="action !== 'new'"
                               formControlName="sku"
                               type="text"
                               (blur)="toUpper('sku')"
                               maxlength="30">
                        <mat-error *ngIf="f['sku'].hasError('required')">Oligatorio</mat-error>
                        <mat-error *ngIf="f['sku'].hasError('minlength')">Mínimo 4 caracteres</mat-error>
                        <mat-error *ngIf="f['sku'].hasError('maxLength')">Máximo 30 caracteres</mat-error>

                        <mat-error *ngIf="articForm.get('sku')?.errors?.['apiError']">{{
                            articForm.get('sku')?.errors?.['apiError'] }}</mat-error>

                    </mat-form-field>
                </div>



                <!-- url -->
                <div class="col-6">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Web</mat-label>
                        <input matInput
                               type="text"
                               formControlName="url"
                               maxlength="150">
                        <mat-error *ngIf="f['url'].hasError('minlength')">Mínimo 4 caracteres</mat-error>

                        <mat-error *ngIf="articForm.get('url')?.errors?.['apiError']">{{
                            articForm.get('url')?.errors?.['apiError'] }}</mat-error>
                    </mat-form-field>
                </div>





                <!-- descripcion -->
                <div class="col-9">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <input matInput
                               type="text"
                               formControlName="descripcion"
                               maxlength="150">
                        <mat-error *ngIf="f['descripcion'].hasError('minlength')">Mínimo 4 caracteres</mat-error>
                        <mat-error *ngIf="f['descripcion'].hasError('maxLength')">Máximo 255 caracteres</mat-error>
                    </mat-form-field>
                </div>


                <!-- swLote -->
                <div class="col-3">
                    
                    <div *ngIf="(articulo.artStock! > 0)"
                         class="m-b-20 text-success">
                        <span *ngIf="articulo.swLote === '0'"> No gestiona lotes</span>
                        <span *ngIf="articulo.swLote === '1'"> Gestiona lotes</span>
                    </div>

                    <div *ngIf="(articulo.artStock! < 1)"
                         class="m-b-20">
                        <mat-label class="msr-3">Gestión de lotes:</mat-label>
                        <mat-radio-group aria-label="Select swLote"
                                         formControlName="swLote"
                                         color="primary"
                                         required>
                            <mat-radio-button value="1">Si</mat-radio-button>
                            <mat-radio-button value="0">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <hr>

                <!-- alto -->
                <div class="col-1">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Alto</mat-label>
                        <input matInput
                               formControlName="alto"
                               (blur)="calcVolumen()"
                               mask="separator"
                               style="text-align: right;">
                        <mat-hint>Cm.</mat-hint>
                        <mat-error *ngIf="f['alto'].hasError('pattern')">Decimales no permitidos</mat-error>
                    </mat-form-field>
                </div>



                <!-- ancho -->
                <div class="col-1">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Ancho</mat-label>
                        <input matInput
                               formControlName="ancho"
                               (blur)="calcVolumen()"
                               mask="separator"
                               style="text-align: right;">
                        <mat-hint>Cm.</mat-hint>
                        <mat-error *ngIf="f['ancho'].hasError('pattern')">Decimales no permitidos</mat-error>
                    </mat-form-field>
                </div>



                <!-- largo -->
                <div class="col-1">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Largo</mat-label>
                        <input matInput
                               formControlName="largo"
                               (blur)="calcVolumen()"
                               mask="separator"
                               style="text-align: right;">
                        <mat-hint>Cm.</mat-hint>
                        <mat-error *ngIf="f['largo'].hasError('pattern')">Decimales no permitidos</mat-error>
                    </mat-form-field>
                </div>



                <!-- peso -->
                <div class="col-1">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Peso</mat-label>
                        <input matInput
                               formControlName="peso"
                               mask="separator"
                               style="text-align: right;">
                        <mat-hint>Kg.</mat-hint>
                    </mat-form-field>
                </div>


                <!-- unidadesCaja -->
                <div class="col-2">
                    <mat-form-field class="example-full-width"
                                    appearance="outline">
                        <mat-label>Unidades Caja</mat-label>
                        <input matInput
                               formControlName="unidadesCaja"
                               mask="separator"
                               style="text-align: right;">
                    </mat-form-field>
                </div>




                <!-- volumen -->
                <div class="col-md-2">
                    <div *ngIf="articulo.volumen"
                         class="form-group">
                        <label class="form-control-label">Volumen:</label>
                        <div class="mt-1">{{articulo.volumen | number: '1.4-4':'es'}} <small>m3</small></div>
                    </div>
                </div>





                <!-- foto -->
                <div class="col-4">
                    <!-- <span *ngIf="articulo.id"> -->
                    <span>
                        <div class="text-center mt4 ">
                            <img [src]="fotoDir +'/articulos/'+ articulo.foto"
                                 loading="lazy"
                                 alt="Foto"
                                 class="img-thumbnail img-avatar pointer mt-1"
                                 matTooltip="Cambiar foto"
                                 (click)=" abrirModal(articulo)">
                        </div>
                    </span>
                </div>



            </div> <!-- row -->




            <div class="row">

                <!-- Botones -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                    <div class="example-button-row">
                        <button mat-raised-button
                                color="primary"
                                type="submit"
                                [disabled]="!articForm.valid"
                                [disabled]="loading">
                            {{txtSubmit}}
                        </button>

                        <button mat-raised-button
                                type="button"
                                color="warn"
                                (click)="cancelarClick()"
                                [disabled]="loading"
                                [mat-dialog-close]="1"
                                tabindex="-1">
                            Cancelar
                        </button>

                    </div>
                </div>

            </div> <!-- row -->



        </form>


    </div>
</div>