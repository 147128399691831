import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { IApiResp } from 'app/interfaces/api-resp';
import { IEmail } from 'app/interfaces/email';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
  ) { }

  enviaEmail(data: IEmail) {
    const path = `${this.apiURL}/enviaEmail`;
    return this.http.post<IApiResp>(path, data)
  }

}
