import { UsuarioService } from './../../services/datos/usuario.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public usuarioSrv: UsuarioService,
    private router: Router
  ) { }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   if (this.usuarioSrv.getUsr()) {
  //     return true;
  //   }
  //   this.router.navigate(['/auth/login']);
  //   return false;
  // }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.usuarioSrv.validarToken()
      .pipe(
        tap((logeado: unknown) => {
          if (!logeado) this.router.navigateByUrl('/auth/login');
        })
      )
  }



}
