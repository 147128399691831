/* eslint-disable @typescript-eslint/no-explicit-any */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

// Módulos
import { MaterialModule } from 'app/auth/material/material.module';

// Servicios 
import { ModalPdfService } from 'app/services/modal-pdf.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { UploadFileService } from 'app/services/upload-file.service';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';


@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.css'],
  standalone: true,
  imports: [CommonModule, MaterialModule, FeatherIconsComponent],
})
export class ModalPdfComponent {

  loading: boolean = false;

  public pdfSubir: File | undefined;
  public imgTemp: any = null;

  constructor(
    public modalPdfSrv: ModalPdfService,
    private uploadFileSrv: UploadFileService,
    private notificacionesService: NotificacionesService,
  ) { }



  cerrarModal() {
    this.imgTemp = null;
    this.modalPdfSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    if (this.loading) return;
    // Cerrar modal al hacer click fuera del modal si no está subiendo un archivo
    if ((event.target as HTMLElement).classList.contains('modal')) {
      this.cerrarModal();
    }
  }


  subirPdf(event: Event) {

    const inputElement = event.target as HTMLInputElement;
    const file: File | undefined = inputElement.files?.[0] || undefined;

    this.loading = true;
    if (file) this.uploadFileSrv.uploadFoto(this.modalPdfSrv.id, file, this.modalPdfSrv.tipo)
      .then(resp => {
        this.loading = false;
        if (!resp.error) {

          // emitir el pdf a los subscriptores
          this.modalPdfSrv.nuevoPdf.emit(resp.data);

          this.notificacionesService.aviso('success', 'Pdf subido');
          this.cerrarModal();
        } else {
          console.log('Error en la subida del pdf', resp)
          this.notificacionesService.aviso('error', resp.mensaje);
        }
      });
  }


  borrarFoto() {
    this.uploadFileSrv.imgDelete(this.modalPdfSrv.tipo, this.modalPdfSrv.id)
      .subscribe(resp => {
        this.loading = false;
        if (!resp.error) {
          this.notificacionesService.aviso('info', 'Pdf eliminado');
        } else {
          this.notificacionesService.aviso('error', resp.mensaje);
        }

        this.cerrarModal();

        // emitir la imagen a los subscriptores
        this.modalPdfSrv.nuevoPdf.emit(resp.data);
      })
  }

}
