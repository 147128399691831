/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { NotificacionesService } from '../notificaciones.service';
import { IApiResp } from "app/interfaces/api-resp";
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { Observable } from 'rxjs';
import { AvisoMensaje, IAvisos } from 'app/interfaces/avisos';

@Injectable({
  providedIn: 'root'
})
export class AvisosService {
  apiURL: string = environment.apiURL;
  _listAvisos: IAvisos | undefined

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  avisoNew(data: any) {
    // "id_cliente" => "required|integer|existeId[cliente]",
    // "tipo" => "required",
    // "mensaje" => "min_length[4]"
    const path = `${this.apiURL}/avisos/avisoNew`;
    return this.http.post<IApiResp>(path, data)
  }

  getAvisosLista(_opciones: IListaOpciones = {}) {
    const path = `${this.apiURL}/avisos/listFiltro`;
    const data = {
      id_cliente: _opciones.id_cliente || null
    };

    return this.http.post<IApiResp>(path, data)
  }

  marcaLeido(aviso: AvisoMensaje) {
    // Marca un aviso como leído
    const id = aviso.id
    const path = `${this.apiURL}/avisos/marcaLeido/${id}`;
    return this.http.get<IApiResp>(path);
  }

  marcarTodoLeido(id_cliente: number) {
    // Marca todos los avisos del cleiente como leídos 
    const path = `${this.apiURL}/avisos/marcarTodoLeido/${id_cliente}`;
    return this.http.get<IApiResp>(path);
  }


}
