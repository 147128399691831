import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard/panel',
    title: 'Panel',
    icon: 'pie-chart',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'stock',
    title: 'Stock',
    icon: 'package',
    class: '',
    groupTitle: false,
    submenu: [],
  },


  {
    path: 'articulos',
    title: 'Artículos',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },


  {
    path: '',
    title: 'Pedidos',
    icon: 'clipboard',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'solicitud-pedidos',
        title: 'Solicitudes',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pedidos',
        title: 'Procesados',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },



  {
    path: '',
    title: 'Entradas',
    icon: 'truck',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'entradas',
        title: 'Solicitudes',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'entradas-validadas',
        title: 'Procesadas',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },



];
//path: 'advance-table',
