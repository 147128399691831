/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";

// servicios
import { UsuarioService } from "./usuario.service";
import { NotificacionesService } from "../notificaciones.service";

// interfaces
import { IEntrada } from "app/interfaces/entrada";
import { IListaOpciones } from "app/interfaces/lista-opciones";
import { IApiResp } from "app/interfaces/api-resp";
import { ITransaccion } from "app/interfaces/transacciones";



@Injectable({
  providedIn: 'root'
})
export class TransaccionesService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private router: Router,
    private notificacionesSrv: NotificacionesService,
  ) { }

  getLista() {
    // Devulve la lista de transacciones
    const path = `${this.apiURL}/transacciones/list`;
    const lista = this.http.get<IApiResp>(path)
    return lista;
  }


  getListaFiltrada(_opciones: IListaOpciones = {}) {
    // Devulve la lista filtrada
    const path = `${this.apiURL}/transacciones/listFiltro`;

    const data = {
      id_almacen: _opciones.id_almacen || null,
      id_cliente: _opciones.id_cliente || null,
      id_articulo: _opciones.id_articulo || null,
      id_ubicacion: _opciones.id_ubicacion || null
    };

    return this.http.post<IApiResp>(path, data)
  }

  update(transaccion: ITransaccion) {
    const path = `${this.apiURL}/transacciones/update/${transaccion.id}`;

    return this.http.post<IApiResp>(path, transaccion)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }

  delete(id: number) {
    const path = `${this.apiURL}/transacciones/delete/${id}`;
    return this.http.get<IApiResp>(path)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  TransaccionEntrada(datos: any) {
    const path = `${this.apiURL}/transacciones/transaccionEntrada`;

    return this.http.post<IApiResp>(path, datos)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }


  TransaccionSalidaPlaya(entrada: IEntrada) {
    const path = `${this.apiURL}/transacciones/transaccionSalidaPlaya`;

    return this.http.post<IApiResp>(path, entrada)
      .pipe(
        catchError(error => {
          console.log('ERROR:', error);
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }


}
