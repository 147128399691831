import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { InConfiguration } from '@core';
import { CommonModule, DOCUMENT } from '@angular/common';

//componentes
import { BreadcrumbComponent } from "../shared/components/breadcrumb/breadcrumb.component";

//módulos
import { MaterialModule } from 'app/auth/material/material.module';
import { FormsModule } from '@angular/forms';

// Interfaces
import { IUsuario } from 'app/interfaces/usuario';

// servicios
import { ConfigService } from './config.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-config',
  standalone: true,
  templateUrl: './config.component.html',
  styleUrl: './config.component.scss',
  imports: [FormsModule, MaterialModule, BreadcrumbComponent, CommonModule]
})
export class ConfigComponent {

  // breadscrums
  breadscrums = [
    {
      title: 'Configuración',
      items: ['Usuario'],
      active: 'Preferencias',
    },
  ];

  // propiedades
  selectedBgColor = 'white';
  maxHeight?: string;
  maxWidth?: string;
  showpanel = false;
  isOpenSidebar?: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  public innerHeight?: number;
  headerHeight = 60;
  isRtl = false;
  public config!: InConfiguration;

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';

  public fotoDir: string = environment.fotoDir;  
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    public userSrv: UsuarioService
  ) {
    //super();
  }

  userdata: IUsuario = {};


  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.config = this.configService.configData;
    this.userdata = this.userSrv.userdata;
  }


  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    this.selectedBgColor = localStorage.getItem('choose_skin_active') as string;

    if (localStorage.getItem('menuOption')) {
      if (localStorage.getItem('menuOption') === 'menu_dark') {
        this.isDarkSidebar = true;
      } else if (localStorage.getItem('menuOption') === 'menu_light') {
        this.isDarkSidebar = false;
      }
    }

    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'dark') {
        this.isDarTheme = true;
      } else if (localStorage.getItem('theme') === 'light') {
        this.isDarTheme = false;
      }
    }

  }



  // Métodos  //


  selectTheme(theme: string) {
    console.log("__selectTheme e:", theme);

    this.selectedBgColor = theme;

    const validThemes = ['white', 'black', 'purple', 'orange', 'cyan', 'green', 'blue'];

    // Validar que el tema sea uno de los permitidos
    if (validThemes.includes(this.selectedBgColor)) {
      validThemes.forEach(validTheme => {
        this.renderer.removeClass(this.document.body, 'theme-' + validTheme);
      });
      this.renderer.addClass(this.document.body, 'theme-' + this.selectedBgColor);

      localStorage.setItem('choose_skin', 'theme-' + this.selectedBgColor);
      localStorage.setItem('choose_skin_active', this.selectedBgColor);
    } else {
      console.error('Tema no válido:', this.selectedBgColor);
    }
  }


  lightSidebarBtnClick() {
    this.renderer.removeClass(this.document.body, 'menu_dark');
    this.renderer.removeClass(this.document.body, 'logo-black');
    this.renderer.addClass(this.document.body, 'menu_light');
    this.renderer.addClass(this.document.body, 'logo-white');
    const menuOption = 'menu_light';
    localStorage.setItem('choose_logoheader', 'logo-white');
    localStorage.setItem('menuOption', menuOption);
  }

  darkSidebarBtnClick() {
    this.renderer.removeClass(this.document.body, 'menu_light');
    this.renderer.removeClass(this.document.body, 'logo-white');
    this.renderer.addClass(this.document.body, 'menu_dark');
    this.renderer.addClass(this.document.body, 'logo-black');
    const menuOption = 'menu_dark';
    localStorage.setItem('choose_logoheader', 'logo-black');
    localStorage.setItem('menuOption', menuOption);
  }

  lightThemeBtnClick() {
    this.renderer.removeClass(this.document.body, 'dark');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_dark');
    this.renderer.removeClass(this.document.body, 'logo-black');
    if (localStorage.getItem('choose_skin')) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem('choose_skin') as string
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
    }

    this.renderer.addClass(this.document.body, 'light');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, 'menu_light');
    this.renderer.addClass(this.document.body, 'logo-white');
    this.renderer.addClass(this.document.body, 'theme-white');
    const theme = 'light';
    const menuOption = 'menu_light';
    this.selectedBgColor = 'white';
    this.isDarkSidebar = false;
    localStorage.setItem('choose_logoheader', 'logo-white');
    localStorage.setItem('choose_skin', 'theme-white');
    localStorage.setItem('theme', theme);
    localStorage.setItem('menuOption', menuOption);
  }

  darkThemeBtnClick() {
    this.renderer.removeClass(this.document.body, 'light');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_light');
    this.renderer.removeClass(this.document.body, 'logo-white');
    if (localStorage.getItem('choose_skin')) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem('choose_skin') as string
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
    }
    this.renderer.addClass(this.document.body, 'dark');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, 'menu_dark');
    this.renderer.addClass(this.document.body, 'logo-black');
    this.renderer.addClass(this.document.body, 'theme-black');
    const theme = 'dark';
    const menuOption = 'menu_dark';
    this.selectedBgColor = 'black';
    this.isDarkSidebar = true;
    localStorage.setItem('choose_logoheader', 'logo-black');
    localStorage.setItem('choose_skin', 'theme-black');
    localStorage.setItem('theme', theme);
    localStorage.setItem('menuOption', menuOption);
  }



  rowsLimitChange() {
    console.log('rowsLimitStr ', this.rowsLimitStr)
    localStorage.setItem('linTablas', this.rowsLimitStr);
  }




}
