<div class="centered-card">

    <mat-card class="custom-card">

        <mat-card-header>
            <div mat-card-avatar
                 class="login-header-image"></div>
            <mat-card-title><b>Innova</b>Cen</mat-card-title>
            <mat-card-subtitle>Intranet del cliente</mat-card-subtitle>
        </mat-card-header>



        <mat-card-content>

            <!-- Errores y avisos -->
            <div *ngIf="error"
                 class="mt-2 mb-4 alert alert-danger alert-dismissible fade show"
                 role="alert">
                {{this.error}}
                <button type="button"
                        class="btn-close"
                        (click)="this.error=''"
                        aria-label="Close"></button>
            </div>

            <div *ngIf="aviso"
                 class="mt-2 mb-4 alert alert-warning alert-dismissible fade show"
                 role="alert">
                {{this.aviso}}
                <button type="button"
                        class="btn-close"
                        (click)="this.aviso=''"
                        aria-label="Close"></button>
            </div>



            <form [formGroup]="loginForm"
                  (ngSubmit)="enviar()"
                  autocomplete="off"
                  id="loginform">


                <!-- username -->
                <div class="row mt-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Usuario</mat-label>
                        <input matInput
                               formControlName="username">
                        <mat-icon matPrefix>alternate_email</mat-icon>

                        <!-- <mat-error *ngIf="enviado && f['username'].errors">
                            {{loginForm.get('username')?.errors?.['apiError'] }}
                        </mat-error> -->

                    </mat-form-field>
                </div>


                <!-- password -->
                <div class="row mt-2">
                    <mat-form-field appearance="outline"
                                    class="col-12">
                        <mat-label>Contraseña</mat-label>
                        <input matInput
                               formControlName="password"
                               [type]="hidePsw ? 'password' : 'text'">
                        <mat-icon matPrefix>key</mat-icon>
                        <button mat-icon-button
                                matSuffix
                                (click)="togglePasswordVisibility()"
                                type="button">
                            <mat-icon>{{hidePsw ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>


                <!-- recordar y olvidado password -->
                <div class="row">
                    <div class="col-6 d-flex align-items-center justify-content-start">
                        <mat-checkbox formControlName="remember"
                                      color="primary">Recordar</mat-checkbox>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end">
                        <a class="d-flex align-items-center link-unstyled"
                           href="#"
                           (click)="recordarPsw()">
                            <mat-icon matPrefix
                                      class="mr-2">lock</mat-icon>
                            <p class="mb-0">Contraseña olvidada</p>
                        </a>
                    </div>
                </div>



                <!-- submit -->
                <button [disabled]="loading"
                        mat-fab
                        extended
                        class="w-100"
                        color="primary"
                        type="submit">
                    <i *ngIf="loading"
                       class="material-icons spinner">autorenew</i>
                    Entrar
                </button>

            </form>


        </mat-card-content>
    </mat-card>


</div>