/**
 * constantes de la app
 */


//  Artículos   
export const NO_DEFECTUOSO = '1';
export const DEFECTUOSO = '2';



//  ESTADOS en tablas   //


// Campañas (Estados de recogidas)
export const ESTADO_CAMPANYA_PENDIENTE = '1';
export const ESTADO_CAMPANYA_PARCIALMENTE_ASIGNADA = '2';
export const ESTADO_CAMPANYA_ASIGNADA = '3';
export const ESTADO_CAMPANYA_EN_PROCESO = '4';
export const ESTADO_CAMPANYA_COMPLETADA = '6';
export const ESTADO_CAMPANYA_INCIDENCIA = '8';


// Pedidos
export const ESTADO_PEDIDO_PENDIENTE = '1';
export const ESTADO_PEDIDO_SERVIDO = '3';
export const ESTADO_PEDIDO_CANCELADO = '4';
export const ESTADO_PEDIDO_SERVIDO_PARCIALMENTE = '5';
export const ESTADO_PEDIDO_INCIDENCIA = '8';


// Preparaciones
export const ESTADO_PREPARACION_PENDIENTE = '1';
export const ESTADO_PREPARACION_EMPEZADO = '2';
export const ESTADO_PREPARACION_COMPLETADA = '3';
export const ESTADO_PREPARACION_ERROR = '8';


// Repartos
export const ESTADO_REPARTO_NORMAL = '1';
export const ESTADO_REPARTO_FINALIZADO = '3';
export const ESTADO_REPARTO_CANCELADO_EN_PEDIDO = '5';
export const ESTADO_REPARTO_INCIDENCIA = '8';


// UIC's
export const ESTADO_UIC_DISPONIBLE = '1';
export const ESTADO_UIC_EN_USO = '2';
export const ESTADO_UIC_CADUCADO = '3'; 
