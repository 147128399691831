/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */

// componentes y módulos
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from 'app/auth/material/material.module';
import { FormBuilder } from '@angular/forms';

// interfaces
import { IDialogData } from '../articulo-dialog/articulo-dialog.component';

// servicios
import { UsuarioService } from 'app/services/datos/usuario.service';
import { NotificacionesService } from 'app/services/notificaciones.service';

// librerias y entorno
import { environment } from 'environments/environment';
import { ArticulosService } from 'app/services/datos/articulos.service';
import { ErroresModalComponent } from './errores-modal/errores-modal.component';
import { UploadFileService } from 'app/services/upload-file.service';


@Component({
  selector: 'app-import',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './import-dialog.component.html',
  styleUrl: './import-dialog.component.scss'
})
export class ImportDialogComponent {

  public pantillasDir: string = environment.pantillasDir;

  public loading: boolean = false
  public archivoSeleccionado: boolean = false
  private fileToImport: any = null

  private guardado_como: string = ''

  constructor(
    public dialogRef: MatDialogRef<ImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,

    private uploadSrv: UploadFileService,
    private fb: FormBuilder,
    private errModal: MatDialog,
    public userSrv: UsuarioService,
    public articulosSrv: ArticulosService,
    private notificSrv: NotificacionesService,
  ) { }


  // Upload el fichero xls
  archivoSeleccionadoChange(event: any) {
    this.archivoSeleccionado = event.target.files.length > 0;
    this.fileToImport = event.target.files[0]

    const inputElement = event.target as HTMLInputElement;
    const file: File | undefined = inputElement.files?.[0] || undefined;

    this.loading = true;
    if (file) this.uploadSrv.uploadXls(file, 'articulos')
      .then(resp => {
        this.loading = false;
        console.log('__archivoSeleccionadoChange()', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje)
          return
        }
        this.guardado_como = resp.data.guardado_como 
      })
  }


  enviarClick() {
    // Importar registros de artículos desde un xls que se encuantra en data/import/articulos
    this.notificSrv.aviso('info', `Importando artículos desde ${this.fileToImport.name}`)
    this.loading = true

    this.articulosSrv.import_xls(this.userSrv.userdata.cliente?.id!, this.guardado_como)
      .subscribe(resp => {
        console.log('__enviarClick()', resp)
        this.loading = false
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje)

          if (resp.totales && resp.totales.lineasErr) {
            this.errModal.open(ErroresModalComponent, {
              data: resp.data
            });
          }

        }

        this.notificSrv.aviso('success', resp.mensaje)

      })

    this.dialogRef.close();
    return
  }

  cancelarClick() {
    this.dialogRef.close();
    return
  }

}
